import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import firebase from "../../config/firebase";
import "./index.css";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import VerifiedIcon from "./../../icons/verification.png";
import {
  query,
  getFirestore,
  collection,
  onSnapshot,
  where,
} from "firebase/firestore";
const UserVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.slice(19);
  const db = getFirestore(firebase);
  const [loading, setLoading] = useState(true);
  const [currentStudent, setCurrentStudent] = useState();
  useEffect(() => {
    async function fetchMyAPI() {
      const q = query(collection(db, "students"), where("uid", "==", path));
      onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          navigate("/not-found");
        } else {
          querySnapshot.forEach((doc) => {
            setCurrentStudent(doc.data());
          });
          setLoading(false);
        }
      });
    }
    fetchMyAPI();
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loading-main">
          <CircularProgress size={50} style={{ color: "#fff" }} />
        </div>
      ) : (
        <div className="data-main">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={1}></Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={10}>
                <h1
                  className="page-heading"
                  style={{ marginBottom: "15px", color: "#fff" }}
                >
                 iHunar Certificate Verification
                </h1>
                <Grid container spacing={2}>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Full Name</p>
                      <p className="student-details-value">
                        {currentStudent.fullName}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Father's Name</p>
                      <p className="student-details-value">
                        {currentStudent.fatherName}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">class Name</p>
                      <p className="student-details-value">
                        {currentStudent.className}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">course</p>
                      <p className="student-details-value">
                        {currentStudent.course}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Batch</p>
                      <p className="student-details-value">
                        Batch {currentStudent.batch}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">course Duration</p>
                      <p className="student-details-value">
                        {currentStudent.courseDuration}{" "}
                        {currentStudent.courseDuration === "1"
                          ? "Month"
                          : "Months"}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Passed date</p>
                      <p className="student-details-value">
                        {currentStudent.passedDate}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Verified</p>
                      <img src={VerifiedIcon} height={28} width={28} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={1}></Grid>
            </Grid>
          </Box>
        </div>
      )}
    </div>
  );
};
export default UserVerification;
