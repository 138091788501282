import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, UserVerification } from "../../pages";
const NavigationRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/user-verification/:id" element={<UserVerification />} />
      </Routes>
    </BrowserRouter>
  );
};
export default NavigationRouter;
