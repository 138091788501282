import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBxiyw8Vj76haXG1aOhimIzw7TGb1QlV1Q",
  authDomain: "ihunar-admin.firebaseapp.com",
  projectId: "ihunar-admin",
  storageBucket: "ihunar-admin.appspot.com",
  messagingSenderId: "457976628118",
  appId: "1:457976628118:web:dc60ad64357a4e4f0ed3b7",
  measurementId: "G-6XHTDRGZWB",
};
const firebase = initializeApp(firebaseConfig);
export default firebase;
