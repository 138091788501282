import React from "react";
import "./index.css";
const Home = () => {
  return (
    <div>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
            <h2>Page not found</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
